// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-listing-js": () => import("/zeit/494fcb1b/src/templates/listing.js" /* webpackChunkName: "component---src-templates-listing-js" */),
  "component---src-pages-404-js": () => import("/zeit/494fcb1b/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artiklar-js": () => import("/zeit/494fcb1b/src/pages/artiklar.js" /* webpackChunkName: "component---src-pages-artiklar-js" */),
  "component---src-pages-bostader-js": () => import("/zeit/494fcb1b/src/pages/bostader.js" /* webpackChunkName: "component---src-pages-bostader-js" */),
  "component---src-pages-bygga-hus-js": () => import("/zeit/494fcb1b/src/pages/bygga-hus.js" /* webpackChunkName: "component---src-pages-bygga-hus-js" */),
  "component---src-pages-index-js": () => import("/zeit/494fcb1b/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-salja-bostad-js": () => import("/zeit/494fcb1b/src/pages/salja-bostad.js" /* webpackChunkName: "component---src-pages-salja-bostad-js" */),
  "component---src-pages-utland-js": () => import("/zeit/494fcb1b/src/pages/utland.js" /* webpackChunkName: "component---src-pages-utland-js" */)
}

